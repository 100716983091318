<template>
  <Stack
    :as="as"
    v-bind="$attrs"
    :align="align"
    :class="[
      style.vStack,
      {
        [style.full]: full,
      },
    ]"
    v-on="$listeners"
  >
    <slot></slot>
  </Stack>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  useCssModule,
} from '@vue/composition-api';
import Stack, { AlignType } from './Stack.vue';

export default defineComponent({
  components: {
    Stack,
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
    full: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String as PropType<AlignType>,
      default: 'start',
    },
  },
  setup() {
    return {
      style: useCssModule(),
    };
  },
});
</script>

<style lang="scss" module>
.vStack {
  flex-direction: column;
}

.full {
  width: 100%;
}
</style>
