import { DirectiveOptions } from 'vue';

export default {
  unbind,
  update,
  inserted,
} as DirectiveOptions;

type Binding = {
  arg: SkeletonShape,
  value: boolean | ISkeletonProps,
};

const isElement = (node: Node): node is HTMLElement => node.nodeType === 1;

const skeletonStyleClass = '__SKELETON__';

const keyframeRule = `
  @keyframes ${skeletonStyleClass}loading {
    0% {
      transform: translateX(-100%);
    }
    50% {
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const beforeRule = `
  .${skeletonStyleClass}::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #e9ecf3;
    position: absolute;
    z-index: 99;
  }
`;
const afterRule = `
  .${skeletonStyleClass}::after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, .3),
      hsla(0, 0%, 100%, 0)
    );
    -webkit-animation: ${skeletonStyleClass}loading 1.5s infinite;
    animation: ${skeletonStyleClass}loading 1.5s infinite;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 100;
  }
`;

const state = new Map<HTMLElement, {
  parentElement: HTMLElement,
  skeletonElement: HTMLElement,
}>();

function unbind(element: Node) {
  if (!isElement(element)) return;
  const { parentElement, skeletonElement } = state.get(element)!;
  const newChild = skeletonElement.firstElementChild;
  newChild && parentElement.replaceChild(newChild, skeletonElement);
  state.delete(element);
  if (!state.size) document.getElementById(skeletonStyleClass)?.remove();
}

function inserted(
  element: HTMLElement,
  binding: Binding,
) {
  const styleElement = document.getElementById(skeletonStyleClass);
  if (!styleElement) setStyle([keyframeRule, beforeRule, afterRule]);
  state.set(element, {
    parentElement: element.parentElement!,
    skeletonElement: document.createElement('div'),
  });
  update(element, binding);
}

function update(
  element: HTMLElement,
  binding: Binding,
) {
  if (!element) return;
  const { display, margin } = window.getComputedStyle(element);

  if (display === 'none') return;

  const isLoading = typeof binding.value === 'boolean' ? binding.value : binding.value.value;
  const { parentElement, skeletonElement } = state.get(element)!;
  const isShowSkeleton = (Array.from(parentElement?.children || [])).includes(skeletonElement);

  if (isLoading) {
    const shape: SkeletonShape = binding.arg || 'rectangle';
    const { value } = binding;
    const skeletonStyle = {
      position: 'relative',
      overflow: 'hidden',
      margin,
    };

    if (shape === 'circle' && typeof value !== 'boolean') {
      const diameter = getDiameter(element, value);

      Object.assign(skeletonStyle, {
        width: diameter,
        height: diameter,
        borderRadius: '50%',
      });
    }

    skeletonElement.classList.add(skeletonStyleClass);

    Object.assign(
      skeletonElement.style,
      skeletonStyle,
      typeof value !== 'boolean' && value,
    );

    if (!isShowSkeleton) {
      parentElement.replaceChild(skeletonElement, element);
      skeletonElement.append(element);
    }

    return;
  }

  if (isShowSkeleton) {
    parentElement.replaceChild(element, skeletonElement);
  }
}

function setStyle(rules: string[]) {
  const styleElement = Object.assign(
    document.createElement('style'),
    {
      id: skeletonStyleClass,
      textContent: rules.join(''),
    },
  );

  document.head.appendChild(styleElement);
}

function getDiameter(
  element: HTMLElement,
  params: ISkeletonProps,
): string {
  const { width, height } = params || {};
  let size = '';

  if (width || height) {
    const newWidth = width || height || '50px';
    const newHeight = height || width || '50px';
    size = getSmallSize(newWidth, newHeight);
  } else {
    const { clientWidth, clientHeight } = element;
    size = getSmallSize(clientWidth, clientHeight);
  }

  return size;
}

function getSmallSize(
  size1: SkeletonSize,
  size2: SkeletonSize,
): string {
  if (size1 === size2) return typeof size1 === 'number' ? `${size1}px` : size1;

  const arr = [getNumberAndUnit(size1), getNumberAndUnit(size2)];
  arr.sort(([s1], [s2]) => s1 < s2 ? 1 : -1);
  return arr[0][0] + (arr[0]?.[1] || 'px');
}

function getNumberAndUnit(s: SkeletonSize): [number, string?] {
  if (typeof s === 'number') return [s];

  const notNumber = new RegExp(/[^0-9]+$/);
  const unit = s.match(notNumber)?.[0] || '';
  const size = Number(s.replace(notNumber, '')) || 0;

  return [size, unit];
}

type SkeletonSize = string | number;

type SkeletonShape =
  | 'rectangle'
  | 'circle';

interface ISkeletonProps {
  value: boolean;
  width?: SkeletonSize;
  height?: SkeletonSize;
  margin?: SkeletonSize;
  borderRadius?: SkeletonSize;
}
