var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.as,_vm._g({tag:"Component",class:[
    _vm.style.stack,
    _vm.style[("gap-" + _vm.gap)],
    _vm.style[("spacing-" + _vm.spacing)],
    _vm.style[("align-" + _vm.align)],
    {
      'tw-flex-wrap': _vm.flexWrap,
    } ]},_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }