import { stringify } from './string';

export const swapReactive = (array, targetIndex, newIndex) => {
  if (targetIndex === newIndex) return;

  const spliced = array.splice(targetIndex, 1, array[newIndex]);
  array.splice(newIndex, 1, ...spliced);
};

export const clone = (value) => {
  if (Array.isArray(value)) return cloneArray(value);
  else if (isObject(value)) return cloneObject(value);
  return value;
};

export const cloneObject = (object) => {
  if (!object) return;
  const clone = {};

  for (const [key, value] of Object.entries(object)) {
    if (Array.isArray(value)) {
      clone[key] = cloneArray(value);
    } else if (isObject(value) && !isDate(value)) {
      clone[key] = cloneObject(value);
    } else {
      clone[key] = value;
    }
  }

  return clone;
};

export const cloneArray = (array) => {
  const clone = [];

  for (const value of array) {
    if (Array.isArray(value)) {
      clone.push(cloneArray(value));
    } else if (isObject(value)) {
      clone.push(cloneObject(value));
    } else {
      clone.push(value);
    }
  }

  return clone;
};

export const sum = (array) => array.reduce(
  (acc, cur) => {
    const parsed = parseFloat(cur);
    return acc + (parsed ? parsed : 0);
  },
  0,
);

export const hasSameValues = (valueA, valueB) => stringify(valueA) === stringify(valueB);

export const isEmptyObject = (obj) => isObject(obj) && Object.keys(obj).length === 0;

export const isDate = (value) => isObject(value) && value instanceof Date && !isNaN(value.valueOf());

export const isObject = (value) => typeof value === 'object' && value !== null;

export const isPrimitive = (value) => {
  const type = typeof value;
  return (
    type === 'boolean' ||
    type === 'number' ||
    type === 'string' ||
    type === 'symbol'
  );
};

export const trimZero = (num) => parseFloat(num.toString().replace(/^0+/, '')) || 0;

export function isPresent(variable) {
  if (Array.isArray(variable)) {
    const array = variable.filter((item) => isPresent(item));
    return array.length > 0;
  } else if (typeof (variable) === 'object' && !!variable && variable.constructor === Object) {
    let present = false;
    for (const value of Object.values(variable)) {
      present = present || isPresent(value);
    }
    return present;
  } else {
    return !!variable;
  }
}

export const sortObjectAscendingByKey = (object) => Object.fromEntries(
  Object.entries(object).sort(
    ([a], [b]) => a.localeCompare(b),
  ),
);

export const sortObjectItemsAscendingByStringKey = (array, keyName) =>
  array.sort((a, b) => (a[keyName] < b[keyName]) ? 1 : ((b[keyName] < a[keyName]) ? -1 : 0));
