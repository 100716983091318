'use strict';

const preserveCamelCase = (input) => {
  let isLastCharLower = false;
  let isLastCharUpper = false;
  let isBeforeLastCharUpper = false;

  const arr = [];

  Array.from(input).forEach((c) => {
    if (isLastCharLower && /[a-zA-Z]/.test(c) && c.toUpperCase() === c) {
      arr.push('-');
      isLastCharLower = false;
      isBeforeLastCharUpper = isLastCharUpper;
      isLastCharUpper = true;
    } else if (isLastCharUpper && isBeforeLastCharUpper && /[a-zA-Z]/.test(c) && c.toLowerCase() === c) {
      arr.splice(arr.length - 1, 0, '-');
      isBeforeLastCharUpper = isLastCharUpper;
      isLastCharUpper = false;
      isLastCharLower = true;
    } else {
      isLastCharLower = c.toLowerCase() === c;
      isBeforeLastCharUpper = isLastCharUpper;
      isLastCharUpper = c.toUpperCase() === c;
    }

    arr.push(c);
  });

  return arr.join('');
};

export default (input, options) => {
  const caseOptions = Object.assign({
    pascalCase: false,
  }, options);
  const postProcess = (x) => caseOptions.pascalCase ? x.charAt(0).toUpperCase() + x.slice(1) : x;

  let res = input;

  if (Array.isArray(input)) {
    res = input.map((x) => x.trim())
      .filter((x) => x.length)
      .join('-');
  } else {
    res = input.trim();
  }

  if (res.length === 0) {
    return '';
  }

  if (res.length === 1) {
    return caseOptions.pascalCase ? res.toUpperCase() : res.toLowerCase();
  }

  if (/^[a-z\d]+$/.test(res)) {
    return postProcess(res);
  }

  const hasUpperCase = res !== res.toLowerCase();

  if (hasUpperCase) {
    res = preserveCamelCase(res);
  }

  res = res
    .replace(/^[_.\- ]+/, '')
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (m, p1) => p1.toUpperCase());

  return postProcess(res);
};
