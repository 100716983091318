import VueCompositionAPI, {
  ComponentInstance,
  ref,
  unref,
  watch,
} from '@vue/composition-api';
import Vue, { VNode } from 'vue';
import { isEmptyObject } from './utils';
import Toast from '@/components/shared/Toast.vue';

Vue.use(VueCompositionAPI);

const toastContainerId = '__TOAST_CONTAINER__';
const toastContainer = ref<ComponentInstance | null>(null);
const toastQue = ref<ToastQueItem[]>([]);

export function appendToast(
  message: string | VNode,
  appendToastProps?: AppendToastProps,
): ComponentInstance | null {
  const id = performance.now();
  const {
    toastMargin = '1rem',
    ...props
  } = appendToastProps || {};
  toastQue.value.push({ id, message, props });
  if (!toastContainer.value) {
    toastContainer.value = new Vue({
      name: 'ToastContainer',
      components: { Toast },
      setup() {
        const { toastPosition = 'top center', toastWidth } = props;
        const hasBottom = toastPosition.includes('bottom');
        const hasCenter = toastPosition.includes('center');
        const hasRight = toastPosition.includes('right');
        const width = typeof toastWidth === 'number' ? `${toastWidth}px` : toastWidth;
        const style = (() => {
          const verticalPosition = {
            [hasBottom ? 'bottom' : 'top']: toastMargin,
          };
          const horizontalPosition = {
            ...(hasCenter && {
              left: '50%',
              transform: 'translate(-50%)',
            } || {
              [hasRight ? 'right' : 'left']: toastMargin,
            }),
          };
          const flexDirection = hasBottom ? 'column' : 'column-reverse';
          const alignItems = (
            hasCenter && 'center' ||
            hasRight && 'flex-end' ||
            'flex-start'
          );
          return {
            width,
            position: 'fixed',
            ...verticalPosition,
            ...horizontalPosition,
            display: 'flex',
            flexDirection,
            alignItems,
            zIndex: 9999,
          };
        })();

        const handleDestroyed = (toastId: number) => {
          toastQue.value = unref(toastQue).filter(({ id }) => id !== toastId);
        };
        const getToasts = () => unref(toastQue).map(({ id, message, props }) => (
          <Toast
            style={{
              [hasBottom ? 'marginTop' : 'marginBottom']: toastMargin,
            }}
            props={{
              ...props,
              toastId: id,
            }}
            on={{
              destroyed: handleDestroyed,
            }}
            key={id}
          >{ message }</Toast>
        ));

        const unwatch = watch(toastQue, ({ length }) => {
          if (!length) {
            const toastContainerElement = toastContainer.value?.$el;
            toastContainerElement?.remove();
            toastContainer.value = null;
            unwatch();
          }
        });

        return () => (
          <div
            id={toastContainerId}
            style={unref(style)}
          >{ getToasts() }</div>
        );
      },
    }).$mount();
    document.body.appendChild(toastContainer.value.$el);
  }
  return unref(toastContainer) as ComponentInstance;
}

export function appendTagManager(
  target: HTMLElement | Vue,
  data: Object = {},
) {
  if (data === null || isEmptyObject(data)) return;

  const newEl = Object.assign(document.createElement('div'), { id: 'gtmvars' });
  Object.assign(newEl.dataset, data);
  const targetEl = target instanceof HTMLElement ? target : target.$el;
  targetEl.appendChild(newEl);
  window.dataLayer?.push({ event: 'Vars Appended' });
}

type ToastPosition =
  | 'bottom center'
  | 'bottom left'
  | 'bottom right'
  | 'top center'
  | 'top left'
  | 'top right';

interface AppendToastProps {
  toastBackgroundColor?: string;
  toastBorderColor?: string;
  toastBorderRadius?: string;
  toastColor?: string;
  toastMargin?: string;
  toastPosition?: ToastPosition;
  toastTime?: number;
  toastTransitionDuration?: number;
  toastWidth?: number | string;
}

interface ToastQueItem {
  id: number;
  message: string | VNode;
  props?: AppendToastProps;
}
