import { DirectiveOptions } from 'vue';

export default {
  bind,
  update,
  unbind,
} as DirectiveOptions;

type Position =
  | 'top'
  | 'right'
  | 'left'
  | 'bottom';

export type TextAlign =
 | 'left'
 | 'center'
 | 'right';

type Value = {
  className?: string,
  delay?: number,
  isMountBody?: boolean,
  message: string | null,
  position?: Position,
  style?: CSSStyleDeclaration,
  textAlign?: TextAlign,
  width?: string,
};

type Binding = {
  arg: Position,
  value: Value & string,
};

const ANIMATION_DURATION = 100;
const ARROW_SIZE = 6;
const GAP = ARROW_SIZE + 2;
const OFFSET = 8;

const state = new Map<HTMLElement, Value & { destroy?(): Promise<void>, handler(): void }>();

const handleParams = (el: HTMLElement, binding: Binding) => {
  if (!(el instanceof HTMLElement))
    throw new TypeError('Element should be instance of HTMLElement');

  const {
    arg: position = 'bottom',
    value,
  } = binding;

  if (!['top', 'right', 'left', 'bottom'].includes(position))
    throw new TypeError('Argument should be one of top, right, left, bottom');

  const {
    className = '',
    delay = 0,
    isMountBody = false,
    message = value,
    style,
    textAlign = 'center',
    width,
  } = value;

  return {
    className,
    delay,
    isMountBody,
    message,
    position,
    style,
    textAlign,
    width,
  };
};

function bind(el: HTMLElement, binding: Binding) {
  const params = handleParams(el, binding);
  const boundTooltipHandler = () => tooltipHandler(el);

  state.set(el, { ...params, handler: boundTooltipHandler });
  el.addEventListener('pointerenter', boundTooltipHandler);

  const destroyTooltip = () => {
    state.get(el)?.destroy?.();
    document.removeEventListener('pointerdown', destroyTooltip);
  };

  el.addEventListener('pointerdown', destroyTooltip);
  el.addEventListener('pointerleave', destroyTooltip);
  document.addEventListener('pointerdown', destroyTooltip);
}

function tooltipHandler(currentTarget: HTMLElement) {
  if (!currentTarget || !state.has(currentTarget)) return;

  const currentTargetState = state.get(currentTarget)!;

  const {
    className,
    delay,
    message,
    isMountBody,
    position,
    width,
    textAlign,
    style,
  } = currentTargetState;

  if (!message) return;

  const absolutePosition: Partial<CSSStyleDeclaration> = {};
  const bodyMount = isMountBody && position === 'top';
  // TODO: 현재는 position top 일경우에만 body mount 대응
  if (bodyMount) {
    const { top, left } = currentTarget.getBoundingClientRect();
    const { offsetWidth: targetWidth } = currentTarget;
    const { scrollX, scrollY } = window;

    Object.assign(absolutePosition, {
      top: `${top + scrollY}px`,
      left: `${left + scrollX + targetWidth / 2}px`,
    });
  }

  const mountTarget = bodyMount ? document.body : currentTarget;
  Object.assign(mountTarget.style, {
    position: 'relative',
  });

  const container = mountTarget.appendChild(
    Object.assign(document.createElement('div'), {
      className,
      textContent: message,
    }),
  );

  Object.assign(container.style, {
    width,
    backgroundColor: 'black',
    borderRadius: '.25rem',
    color: 'white',
    fontSize: '14px',
    opacity: 0,
    padding: '.25rem .5rem',
    pointerEvents: 'none',
    position: 'absolute',
    textAlign,
    zIndex: '1110',
    ...(!width && { whiteSpace: 'nowrap' }),
    ...(
      position === 'top' && {
        top: 0,
        left: '50%',
        transform: `translateX(-50%) translateY(calc(-100% - ${GAP}px))`,
      } ||
      position === 'right' && {
        top: '50%',
        right: 0,
        transform: `translateX(calc(100% + ${GAP}px)) translateY(-50%)`,
      } ||
      position === 'bottom' && {
        bottom: 0,
        left: '50%',
        transform: `translateX(-50%) translateY(calc(100% + ${GAP}px))`,
      } ||
      position === 'left' && {
        top: '50%',
        left: 0,
        transform: `translateX(calc(-100% - ${GAP}px)) translateY(-50%)`,
      }
    ),
    ...absolutePosition,
  }, style);

  const arrow = container.appendChild(
    document.createElement('div'),
  );

  Object.assign(arrow.style, {
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: `${ARROW_SIZE}px`,
    ...(
      position === 'top' && {
        bottom: 0,
        left: '50%',
        borderColor: 'black transparent transparent transparent',
        transform: 'translateX(-50%) translateY(calc(100% - 1px))',
      } ||
      position === 'right' && {
        top: '50%',
        left: 0,
        borderColor: 'transparent black transparent transparent',
        transform: 'translateX(calc(-100% + 1px)) translateY(-50%)',
      } ||
      position === 'bottom' && {
        top: 0,
        left: '50%',
        borderColor: 'transparent transparent black transparent',
        transform: 'translateX(-50%) translateY(calc(-100% + 1px))',
      } ||
      position === 'left' && {
        top: '50%',
        right: 0,
        borderColor: 'transparent transparent transparent black',
        transform: 'translateX(calc(100% - 1px)) translateY(-50%)',
      }
    ),
  });

  if (position === 'top' || position === 'bottom') {
    const containerRect = container.getBoundingClientRect();
    const {
      left: containerLeft,
      right: containerRight,
    } = containerRect;

    const {
      offsetWidth: documentWidth,
    } = document.documentElement;

    if (containerLeft < OFFSET) {
      if (isMountBody) {
        container.style.left = `${containerRect.width * .5 + OFFSET}px`;

        const {
          left: currentTargetLeft,
        } = currentTarget.getBoundingClientRect();
        arrow.style.left = `${currentTargetLeft}px`;
      } else {
        container.style.left = `calc(50% - ${containerLeft}px + ${OFFSET}px)`;
        arrow.style.left = `calc(50% + ${containerLeft}px - ${OFFSET}px)`;
      }
    } else if (containerRight > documentWidth - OFFSET) {
      if (isMountBody) {
        container.style.left = `${documentWidth - OFFSET - containerRect.width * .5}px`;

        const {
          left: currentTargetLeft,
          width: currentTargetWidth,
        } = currentTarget.getBoundingClientRect();
        arrow.style.left = `${currentTargetLeft + currentTargetWidth * .5 - (documentWidth - OFFSET - containerRect.width)}px`;
      } else {
        container.style.left = `calc(50% - ${containerRight - documentWidth}px - ${OFFSET}px)`;
        arrow.style.left = `calc(50% + ${containerRight - documentWidth}px + ${OFFSET}px)`;
      }
    }
  }

  container.animate([
    { opacity: 0 },
    { opacity: 1 },
  ], {
    delay,
    duration: ANIMATION_DURATION,
    fill: 'forwards',
  });

  const destroy = async () => {
    const startOpacity = delay ? +getComputedStyle(container).opacity : 1;

    await new Promise((resolve) => container.animate([
      { opacity: startOpacity },
      { opacity: 0 },
    ], {
      duration: ANIMATION_DURATION,
    }).onfinish = resolve);

    Object.assign(mountTarget.style, {
      cursor: null,
      position: null,
    });

    container.remove();
  };

  state.set(currentTarget, {
    ...currentTargetState,
    destroy,
  });
}

function update(el: HTMLElement, binding: Binding) {
  const newState = handleParams(el, binding);
  state.has(el) && state.set(el, {
    ...state.get(el)!,
    ...newState,
  });
}

function unbind(el: HTMLElement) {
  if (state.has(el)) {
    el.removeEventListener('pointerenter', state.get(el)!.handler);
    state.get(el)?.destroy?.();
    state.delete(el);
  }
}
