<template>
  <svg :class="htmlClass" version="1.1" xmlns="http://www.w3.org/2000/svg" @click="click">
    <use :href="`${svg}#${name}`" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import svg from '~/svgs/svg-defs.svg';

export default defineComponent({
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    htmlClass: {
      type: [String, Array],
      default: '',
    },
    click: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  setup() {
    return {
      svg,
    };
  },
});
</script>
