import { isInteger, isString, toInteger } from 'lodash';
import moment, { unitOfTime } from 'moment-timezone';
import { useI18n } from './i18n';
import { DefaultDate } from '@/exportables/types/general.d';

const I18n = useI18n();
const currentLocale = I18n.locale;
const currentTimezone = moment.tz(moment.tz.guess()).zoneAbbr();

interface GetAddedDateOptions {
  /**
   * 결과 시간을 지정한 단위로 초기화합니다.
   * * 예) `day`인 경우 해당 일의 00시 00분 00초로 초기화합니다.
   */
  startOf?: unitOfTime.StartOf;
  /**
   * 기준 타임존을 지정합니다.
   */
  timezone?: string;
}
/**
 * 현재 시간에서 더한 시간을 반환합니다.
 * @param after 더할 시간의 양 및 단위
 * @param options 옵션
 * @returns 더해진 시간
 */
export const getAddedDate = (amount: number, unit: unitOfTime.DurationConstructor, options: GetAddedDateOptions = {}): Date => {
  const { startOf, timezone } = options;
  const now = timezone ? moment().tz(timezone) : moment();
  const added = now.clone().add(amount, unit);
  if (startOf) {
    return added.startOf(startOf).toDate();
  }
  return added.toDate();
};

export const defaultDateFormat = currentLocale === 'ko' ? 'YYYY-MM-DD' : 'MMM DD, YYYY';
export const defaultTimeFormat = 'HH:mm:ss';
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`;

export const SECONDS_A_DAY = 86400;
const MINUTES_IN_YEAR = 525600;
const MINUTES_IN_QUARTER_YEAR = 131400;
const MINUTES_IN_THREE_QUARTERS_YEAR = 394200;

export const datetimeFormatter = (date: DefaultDate, format = defaultDateFormat) => date && moment(date).format(format);

export const convertTimezone = (date: DefaultDate, timezoneAbbr = currentTimezone) => date && moment(date).tz(timezoneAbbr);

export const intlDatetimeFormatter = (date: Date | number, options = {}, locale = 'ko-KR') => new Intl.DateTimeFormat(locale, options).format(date);

export const isLeap = (year: number) => new Date(year, 1, 29).getDate() === 29;

export const convertDate = (value: DefaultDate | number) => (
  (value instanceof Date || isString(value) || typeof value === 'number') && new Date(value) ||
  new Date()
);

export const isPassed = (date: DefaultDate, baseDate: DefaultDate = new Date()) => {
  const [d1, d2] = [convertDate(date), convertDate(baseDate)];
  return d1!.getTime() - d2!.getTime() > 0;
};

export const getDate = (datetime?: DefaultDate) => {
  const date = datetime ? convertDate(datetime) : new Date();
  date.setHours(0, 0, 0);
  return date;
};

export const convertMilliSecondToDay = (value: number, initial = 0) => isInteger(value) && value > 0 ? toInteger(value / 1000 / SECONDS_A_DAY) + initial : 0;

export const subtractDates = (date1: DefaultDate, date2: DefaultDate) => {
  const [d1, d2] = [convertDate(date1), convertDate(date2)];
  return d1 && d2 ? +d1 - +d2 : null;
};

export const subtractMonths = (date1: DefaultDate, date2: DefaultDate) => {
  const [d1, d2] = [convertDate(date1), convertDate(date2)];
  if (!d1 || !d2) return 0;

  const yearDiff = d2.getFullYear() - d1.getFullYear();
  const monthDiff = d2.getMonth() - d1.getMonth();
  const result = yearDiff * 12 + monthDiff;
  const sign = Math.sign(result);
  return sign === 0 ? 0 : result + sign;
};

export const durationYearAndMonth = (duration: number) => {
  const year = Math.floor(duration / 12);
  const month = duration % 12;
  const yearStr = year > 0 ? I18n.t('duration.year_count', { year }) : '';
  const monthStr = month > 0 ? I18n.t('duration.month_count', { month }) : '';
  return [yearStr, monthStr].filter(Boolean).join(' ');
};

type Options = {
  includeSeconds?: boolean,
};

export const distanceOfTimeInWords = (f: DefaultDate, t = new Date(), options: Options = {}) => {
  const from = typeof f === 'string' ? new Date(f) : f;
  const to = typeof t === 'string' ? new Date(t) : t;
  const diff = Math.abs(from!.getTime() - to.getTime());
  const minDiff = Math.round(diff / 1000 / 60);
  const secDiff = Math.round(diff / 1000);

  if (!from) return;

  if (minDiff < 2) {
    if (options.includeSeconds) {
      if (secDiff < 5) {
        return I18n.t('datetime.distance_in_words.less_than_x_seconds', { count: 5 });
      } else if (secDiff < 10) {
        return I18n.t('datetime.distance_in_words.less_than_x_seconds', { count: 10 });
      } else if (secDiff < 20) {
        return I18n.t('datetime.distance_in_words.less_than_x_seconds', { count: 20 });
      } else if (secDiff < 40) {
        return I18n.t('datetime.distance_in_words.half_a_minute');
      } else if (secDiff < 60) {
        return I18n.t('datetime.distance_in_words.less_than_x_minutes', { count: 1 });
      } else {
        return I18n.t('datetime.distance_in_words.x_minutes', { count: 1 });
      }
    } else {
      if (minDiff === 0) {
        return I18n.t('datetime.distance_in_words.less_than_x_minutes', { count: 1 });
      } else {
        return I18n.t('datetime.distance_in_words.x_minutes', { count: minDiff });
      }
    }
  } else if (minDiff < 46) {
    return I18n.t('datetime.distance_in_words.x_minutes', { count: minDiff });
  } else if (minDiff < 90) {
    return I18n.t('datetime.distance_in_words.about_x_hours', { count: 1 });
  } else if (minDiff < 1440) {
    return I18n.t('datetime.distance_in_words.about_x_hours', { count: Math.round(minDiff / 60) });
  } else if (minDiff < 2520) {
    return I18n.t('datetime.distance_in_words.x_days', { count: 1 });
  } else if (minDiff < 43200) {
    return I18n.t('datetime.distance_in_words.x_days', { count: Math.round(minDiff / 1440) });
  } else if (minDiff < 86400) {
    return I18n.t('datetime.distance_in_words.about_x_months', { count: Math.round(minDiff / 43200) });
  } else if (minDiff < 525600) {
    return I18n.t('datetime.distance_in_words.x_months', { count: Math.round(minDiff / 43200) });
  }

  let fromYear = from.getFullYear();
  let toYear = to.getFullYear();
  let leapYears = 0;

  if (from.getMonth() >= 3) fromYear += 1;
  if (to.getMonth() < 3) toYear -= 1;

  if (fromYear <= toYear) {
    for (let i = fromYear; i <= toYear; i++) {
      if (isLeap(i)) {
        leapYears += 1;
      }
    }
  }

  const minuteOffsetByLeapYear = leapYears * 1440;
  const minutesWithOffset = minDiff - minuteOffsetByLeapYear;
  const remainder = minutesWithOffset % MINUTES_IN_YEAR;
  const distanceInYears = Math.floor(minutesWithOffset / MINUTES_IN_YEAR);

  if (remainder < MINUTES_IN_QUARTER_YEAR) {
    return I18n.t('datetime.distance_in_words.about_x_years', { count: distanceInYears });
  } else if (remainder < MINUTES_IN_THREE_QUARTERS_YEAR) {
    return I18n.t('datetime.distance_in_words.over_x_years', { count: distanceInYears });
  }
  return I18n.t('datetime.distance_in_words.almost_x_years', { count: distanceInYears + 1 });
};
