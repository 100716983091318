export class Defer<T> {
  promise: Promise<T>;
  reject: typeof Promise['reject'];
  resolve: typeof Promise['resolve'];

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      Object.assign(this, {
        resolve,
        reject,
      });
    });
  }
}
