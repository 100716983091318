import VueCompositionAPI, {
  App,
  createApp,
  h,
  ref,
} from '@vue/composition-api';
import Vue, {
  ComponentOptions,
  VueConstructor,
} from 'vue';

Vue.use(VueCompositionAPI);

export const modalId = `modal-dialog-${performance.now() | 0}`;
const app = ref<App | null>(null);
const modal = ref<Vue | null>(null);

export const useModal = () => ({
  app,
  hide,
  show,
  vm: modal,
});

function setModalArea() {
  const el = document.getElementById(modalId);
  if (el) return;

  document.body.appendChild(
    Object.assign(
      document.createElement('div'),
      { id: modalId },
    ),
  );
}

function show<T extends Vue, V extends VueConstructor>(
  ModalComponent: V,
  props: InstanceType<V>['$props'] = {},
  emits: {
    show?: Function,
    hide?: Function,
  } & {
    [key: string]: Function,
  } = {
    hide,
  },
  options: Omit<ComponentOptions<T>, 'name'> = {},
) {
  hide();
  setModalArea();

  const name = `${ModalComponent.name}App`;

  app.value = createApp({
    name,
    emits: Object.keys(emits),
    ...options,
    setup() {
      return () => h(
        ModalComponent,
        {
          on: emits,
          props,
        },
      );
    },
  });
  modal.value = app.value.mount(`#${modalId}`);
}

function hide() {
  document.getElementById(modalId)?.remove();
  app.value?.unmount();
  app.value = null;
  modal.value = null;
}
