<template>
  <Component
    :is="as"
    :class="[
      style.stack,
      style[`gap-${gap}`],
      style[`spacing-${spacing}`],
      style[`align-${align}`],
      {
        'tw-flex-wrap': flexWrap,
      },
    ]"
    v-on="$listeners"
  >
    <slot></slot>
  </Component>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  useCssModule,
} from '@vue/composition-api';

export type SpacingType = 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly';
export type AlignType = 'center' | 'start' | 'end';

export default defineComponent({
  name: 'StackBox',
  props: {
    as: {
      type: String,
      default: 'div',
    },
    gap: {
      type: Number,
      default: 2,
    },
    spacing: {
      type: String as PropType<SpacingType>,
      default: null,
    },
    align: {
      type: String as PropType<AlignType>,
      default: 'center',
    },
    flexWrap: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      style: useCssModule(),
    };
  },
});
</script>

<style lang="scss" module>
.stack {
  display: flex;
}

.gap-0\.5 {
  gap: 0.125rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-20 {
  gap: 5rem;
}

.spacing-between {
  justify-content: space-between;
}

.spacing-around {
  justify-content: space-around;
}

.spacing-evenly {
  justify-content: space-evenly;
}

.spacing-center {
  justify-content: center;
}

.spacing-end {
  justify-content: flex-end;
}

.spacing-start {
  justify-content: flex-start;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

</style>
