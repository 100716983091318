import { CamelToSnake, SnakeToCamel } from '@/exportables/types/string.d';

const camelRegex = /([a-z0-9])([A-Z])/g;
const snakeRegex = /^[a-z]+(?:_[a-z]+)*$/g;
const urlRegex = /https?:\/\/\S+/g;

export const camelize = (s: string = '') => s.split(' ').map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');

export const pascalize = (s: string = '') => {
  const camelized = camelize(s);
  return camelized[0].toUpperCase() + camelized.slice(1);
};

export const camelToKebab = (s: string) => (
  s
    .replace(camelRegex, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .toLowerCase()
);

export const snakeToKebab = (s: string) => s.replace(/_/g, '-');

export const camelToSnake = (s: string): CamelToSnake<typeof s> => (
  s
    ?.replace(camelRegex, '$1_$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1_$2')
    .toLowerCase()
  || ''
);

export const snakeToCamel = (s: string): SnakeToCamel<typeof s> => s?.replace(/(_[a-z])/g, ($1) => $1.slice(1).toUpperCase()) || '';

export const isCamelCase = (s: string) => camelRegex.test(s);

export const isSnakeCase = (s: string) => snakeRegex.test(s);

export const escape = (s: string) => s.replaceAll('"', '\\"');

export const stringify = (value: NonNullable<any>): string => {
  if (value === undefined) return 'undefined';
  if (value === null) return 'null';
  if (typeof value === 'number') return `${value}`;
  if (typeof value === 'boolean') return value ? 'true' : 'false';
  if (typeof value === 'string') return escape(value);
  if (Array.isArray(value)) return `[${value.map(stringify).join(', ')}]`;
  return `{ ${Object.entries(value).map(([key, value]) => `${escape(key)}: ${stringify(value)}`).join(', ')} }`;
};

/**
 * 주어진 텍스트에서 주어진 정규식이 매칭되는 모든 결과를 리턴합니다. 단, 앞뒤가 공백으로 분리된 텍스트만 매칭됩니다.
 * - 이는 정규식의 맨 앞과 맨 뒤에 각각 `(?<=\s|)`, `(?=\s|)` 패턴을 붙인 것과 동일하게 동작하는 것입니다.
 * @param text 정규식을 실행할 문자열
 * @param regexp 실행할 정규식 (`g` 플래그 필수)
 * @returns 정규식 매칭 결과의 배열
 */
const matchAllSeparated = (text: string, regexp: RegExp) =>
  [...text.matchAll(regexp)]
    .filter(({ [0]: matchUrl, index: matchIndex }) =>
      /\s/.test(text[matchIndex! - 1] ?? ' ') && /\s/.test(text[matchIndex! + matchUrl.length] ?? ' '));

export interface UrlTextToken {
  type: 'url';
  text: string;
}
export interface PlainTextToken {
  type: 'plain';
  text: string;
}
export type TextToken = UrlTextToken | PlainTextToken;

/**
 * 주어진 텍스트를 **URL 텍스트 토큰**({@link UrlTextToken})과 **일반 텍스트 토큰**({@link PlainTextToken})의 배열로 분리합니다.
 * - 공백으로 구분되지 않는 URL은 URL로 인식되지 않습니다.
 *   (예: `사과https://apple.com` 텍스트에서는 URL이 인식되지 않습니다.)
 * - URL 뒤에 이어지는 모든 문자열이 URL로 인식됩니다.
 *   (예: `사과 https://apple.com/test테스트 사과` 텍스트에서는 `https://apple.com/test테스트` 텍스트가 URL이 인식됩니다.)
 * @param sourceText 분리할 원본 문자열
 * @returns 분리한 결과 배열
 * @example
 * splitToUrlAndPlainTextTokens('나는 사과 https://apple.com 나는 오렌지 https://orange.com/good');
 * // 실행 결과는 다음과 같습니다.
 * [
 *   { type: 'plain', text: '나는 사과 ' },
 *   { type: 'url', text: 'https://apple.com' },
 *   { type: 'plain', text: ' 나는 오렌지 ' },
 *   { type: 'url', text: 'https://orange.com/good' },
 * ]
 */
export const splitToUrlAndPlainTextTokens = (sourceText: string): TextToken[] => {
  /** 결과 배열 */
  const result: TextToken[] = [];

  /** 주어진 문자열에서 URL 정규식으로 매치된 모든 결과 */
  const urlMatches = matchAllSeparated(sourceText, urlRegex);

  /** 이전에 매치된 URL 텍스트가 끝나는 인덱스의 다음 인덱스 */
  let prevUrlTextEndedIndex = 0;

  // 매치 결과를 열거하며 결과 배열에 추가한다
  for (const { [0]: urlText, index: urlTextIndex } of urlMatches) {
    // 이번 매치된 URL 문자열 이전의 일반 문자열을 결과 배열에 추가한다 (빈 문자열일 수 있다)
    const prevPlainText = sourceText.substring(prevUrlTextEndedIndex, urlTextIndex!);
    result.push({ type: 'plain', text: prevPlainText });
    prevUrlTextEndedIndex = urlTextIndex! + urlText.length;

    // 이번 매치된 URL 문자열을 결과 배열에 추가한다
    result.push({ type: 'url', text: urlText });
  }

  // 마지막으로 남은 일반 문자열을 결과 배열에 추가한다 (빈 문자열일 수 있다)
  const lastPlainText = sourceText.substring(prevUrlTextEndedIndex);
  result.push({ type: 'plain', text: lastPlainText });

  // 결과 배열에서 빈 문자열 토큰을 제외시킨다
  const resultExcludingEmptyText = result.filter(({ text }) => text.length > 0);

  return resultExcludingEmptyText;
};
