import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import Vue from 'vue';

export const isSchoolInstructorUrl = location.host.split('.')[0].split('-').includes('instructor');
export const BASE_API_URL = isSchoolInstructorUrl ? `https://${window.__env__?.SCHOOL_HOST}/api` : '/api';

export const createClient = (config?: AxiosRequestConfig) => {
  const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    headers: {
      'content-type': 'application/json',
    },
    timeout: 30000,
    withCredentials: isSchoolInstructorUrl,
    ...config,
  });
  const { request, response } = axiosInstance.interceptors;
  const options = { deep: true };
  request.use(({ data, params, ...restConfig }) => {
    const isFormData = data instanceof FormData;
    return {
      ...restConfig,
      ...(data && { data: isFormData ? data : snakecaseKeys(data, options) }),
      ...(params && { params: isFormData ? params : snakecaseKeys(params, options) }),
    };
  });
  response.use(({ data, ...restResponse }) => ({
    ...restResponse,
    ...(data && { data: camelcaseKeys(data, options) }),
  }));
  return axiosInstance;
};

export const cancellableActionSetter = (
  fn: WillBeCancellableAction,
  axiosInstanceName: string = '$http',
  methodNamesWillBeUsed?: string[],
  throwError: boolean = false,
) => {
  const configArgIndices: [keyof AxiosInstance, number][] = [
    ['request', 0],
    ['get', 1],
    ['delete', 1],
    ['head', 1],
    ['options', 1],
    ['post', 2],
    ['put', 2],
    ['patch', 2],
    ['getUri', 0],
  ];
  return async function cancellableAction(
    this: { [key: string]: any },
    ...args: any[]
  ) {
    const { CancelToken, isCancel } = axios;
    const axiosInstance: AxiosInstance =
      this[axiosInstanceName] ||
      this._vm?.[axiosInstanceName] ||
      Vue.prototype[axiosInstanceName];
    if (fn.cancelSource) {
      fn.cancelSource.cancel();
      Object.assign(fn, { cancelSource: null });
    }
    Object.assign(fn, { cancelSource: CancelToken.source() });
    const { token: cancelToken } = fn.cancelSource as CancelTokenSource;
    const originalMethods = new Map();
    for (const [methodName, configArgIndex] of configArgIndices) {
      if (methodNamesWillBeUsed?.length && !methodNamesWillBeUsed?.includes(methodName)) continue;
      const method = axiosInstance[methodName];
      const proxiedMethod = new Proxy(method, {
        apply(target, thisArg, args) {
          const newArgs = Array.from(args);
          newArgs.splice(
            configArgIndex,
            1,
            { ...args[configArgIndex], cancelToken },
          );
          return (target as Function).apply(thisArg, newArgs);
        },
      });
      originalMethods.set(methodName, method);
      Object.assign(axiosInstance, { [methodName]: proxiedMethod });
    }
    try {
      const promise: Promise<AxiosResponse> = fn.apply(this, args);
      for (const [methodName, originalMethod] of originalMethods) {
        Object.assign(axiosInstance, { [methodName]: originalMethod });
      }
      const result = await promise;
      Object.assign(fn, { cancelSource: null });
      return result;
    } catch (error) {
      if (error instanceof Error && isCancel(error) && !throwError) {
        const { message } = error;
        return message;
      } else throw error;
    }
  };
};

interface WillBeCancellableAction extends Function {
  cancelSource: CancelTokenSource | null;
}
