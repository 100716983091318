import 'katex/dist/katex.css';
import Editor from '@toast-ui/editor';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import katex from 'katex';

export const latexPlugin = (editor: Editor | Viewer) => {
  const { codeBlockManager } = (
    editor instanceof Viewer && Viewer ||
    Editor
  );
  codeBlockManager.setReplacer(
    'latex',
    (code: string) => katex.renderToString(code, {
      throwOnError: false,
    }),
  );
};
