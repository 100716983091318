import { Defer } from '@/exportables/utils/Defer';

type AnyFunc = (...args: any) => any;

export function debounce<T extends AnyFunc>(func: T, wait?: number) {
  const state: {
    cancelId: number | null,
    deferred: Defer<ReturnType<T>> | null,
  } = {
    cancelId: null,
    deferred: null,
  };

  const hasWait = Number.isSafeInteger(wait);
  const [timer, cancel] =
    hasWait
      ? [setTimeout, clearTimeout]
      : [requestAnimationFrame, cancelAnimationFrame];

  return function debounced(this: ThisType<T> | void, ...args: Parameters<T>): Promise<ReturnType<T>> {
    state.cancelId && cancel(state.cancelId);
    !state.deferred && (state.deferred = new Defer);

    state.cancelId = +(timer as typeof setTimeout)(
      () => {
        const { deferred } = state;
        try {
          const value = func.apply(this, args);
          deferred?.resolve(value);
        } catch (error) {
          deferred?.reject(error);
        } finally {
          Object.assign(state, {
            cacnelId: null,
            deferred: null,
          });
        }
      },
      wait,
    );

    return state.deferred.promise;
  };
}

export function throttle<T extends AnyFunc>(func: T, wait = 0) {
  let timestamp = -wait;

  return function throttled(this: ThisType<T> | void, ...args: Parameters<T>): ReturnType<T> | void {
    const now = performance.now();

    if (now - timestamp > wait) {
      timestamp = now;
      return func.apply(this, args);
    }
  };
}
