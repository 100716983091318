<template>
  <div class="form-group" :class="additionalClass">
    <label v-if="label" class="control-label input-radio">{{ label }}</label>
    <input
      ref="input"
      :disabled="disabled"
      :placeholder="placeholder"
      class="form-control"
      type="text"
      data-select-visible="false"
    >
    <svg-icon v-if="iconName" :name="iconName" html-class="ic-24" />
  </div>
</template>

<script>
export default {
  name: 'SharedAutocomplete',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    enableNoResult: {
      type: Boolean,
      default: true,
    },
    noResultLabel: {
      type: String,
      default: '검색결과가 없습니다.',
    },
    visibleCount: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
      required: true,
    },
    ulCustomClass: {
      type: String,
      default: '',
    },
    optionalData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    additionalClass: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    isRestrictedUnicode: {
      type: Boolean,
      default: false,
    },
    emptyFormShake: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selected'],
  mounted() {
    const $tagSearchBox = $(this.$refs.input);
    $tagSearchBox.autocomplete(
      {
        autoFocus: true,
        source: (request, response) => {
          $.ajax({
            url: this.url,
            dataType: 'json',
            data: this.queryData($tagSearchBox.val()),
            success: (data) => {
              response($.map(data['results'], (item) => {
                return {
                  id: item.id,
                  value: item.name,
                  name: item.name,
                  data: item.data,
                };
              }));
            },
          });
        },
        response: (event, ui) => {
          if (!ui.content.length) {
            let noResult;
            if (this.enableNoResult) {
              const label = [
                this.noResultLabel,
                (this.isRestrictedUnicode && /[^\u0000-\u007f]/.test($tagSearchBox.val()) ?
                  I18n.t('job_position.list.search_filter.enter_english_keyword') : ''),
              ].join(' ');
              noResult = { value: '', label: label };
            } else {
              const term = $tagSearchBox.val();
              noResult = { id: '', value: term, label: term };
            }

            ui.content.push(noResult);
          }
        },
        select: (event, ui) => {
          const selected = ui.item;
          if (selected.value.length === 0) {
            if (this.emptyFormShake) $(this.$el).animateCss('shake');
            return false;
          }
          this.$emit('selected', selected);
          $tagSearchBox.val('');
          return false;
        },
        focus: (event, _ui) => {
          event.preventDefault();
        },
        open: () => {
          $(this).data('selectVisible', true);
        },
        close: () => {
          $(this).data('selectVisible', false);
        },
      },
    ).autocomplete('widget').addClass(this.ulCustomClass);
    if (this.visibleCount) {
      $tagSearchBox.autocomplete('instance')._renderItem = (ul, item) => {
        let text = item.label;
        if (!!item.data && !!item.data.counts) {
          text = `${item.label} (${item.data.counts[this.visibleCount]})`;
        }
        return $('<li></li>')
          .data('item.autocomplete', item)
          .text(text).appendTo(ul);
      };
    }
  },
  beforeDestroy() {
    $(this.$refs.input).autocomplete('destroy');
  },
  methods: {
    queryData(term) {
      return Object.assign({}, this.optionalData, { term: term });
    },
  },
};
</script>
